<template>
    <div class="row">
        <div class="col-12 px-0 mb-2" v-if="type == 'S' && indicator.S != 0">
            <div class="table-responsive">
                <table class="table text-black">
                    <tr>
                        <td colspan="2"><b>Shine</b></td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            Program SHINE untuk tingkat pemula hingga mahir. Program ini sangat sesuai bagi
                            pengguna untuk memiliki kompetensi dasar berbahasa Inggris secara menyeluruh sebagai
                            standar untuk menempuh ke tingkatan yang lebih tinggi. Segala aspek standar berkomunikasi
                            berbahasa Inggris diperoleh di program ini yang meliputi lima tingkatan: Beginner,
                            Elementary, Intermediate, Upper-Intermediate, dan Advanced.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            Tingkatan (Level) 1-5
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Beginner (level 1)</b> <br/>
                            Seorang pengguna Beginner
                            (level 1) di bahasa Inggris akan 
                            dapat &rarr;
                        </td>
                        <td width="70%">
                            <ul>
                                <li>
                                    memperkenalkan diri secara sederhana dan mengucapkan salam yang umum.
                                </li>
                                <li>
                                    menceritakan dari mana dia dan orang lain 
                                    berasal serta memberikan gambaran dasar 
                                    mengenai kota tempat tinggalnya.
                                </li>
                                <li>
                                    menceritakan tentang keluarga dan kerabat secara 
                                    sederhana, menggambarkan penampilan dan 
                                    kepribadian mereka.
                                </li>
                                <li>
                                    membahas pakaian pada tingkat dasar dan 
                                    mengajukan pertanyaan sederhana kepada 
                                    penjual.
                                </li>
                                <li>
                                    berbicara tentang makanan kesukaan dan 
                                    memesan makanan untuk dibawa pulang.
                                </li>
                                <li>
                                    berbicara tentang kegiatan sehari-hari dan 
                                    mengatur pertemuan dengan teman dan kolega.
                                </li>
                                <li>
                                    menggambarkan kondisi cuaca saat ini dan 
                                    menyarankan kegiatan yang sesuai dengan 
                                    perkiraan cuaca.
                                </li>
                                <li>
                                    berbicara secara umum tentang kesehatannya dan 
                                    menggambarkan gejala medis umum kepada 
                                    seorang dokter.
                                </li>
                                <li>
                                    menjelaskan lokasi rumah dan memberikan arah sederhana.
                                </li>
                                <li>
                                    berbicara tentang hobi dan minat serta menyusun 
                                    rencana untuk melakukan kegiatan bersama 
                                    teman atau kolega.
                                </li>
                                <li>
                                    menyelesaikan transaksi dasar di hotel, termasuk 
                                    check-in dan check-out.
                                </li>
                                <li>
                                    membahas produk umum, melakukan pembelian 
                                    sederhana, dan mengembalikan barang yang 
                                    rusak.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Elementary (level 2)</b> <br/>
                            Seorang pengguna Elementary
                            (level 2) di bahasa Inggris akan 
                            dapat &rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    mengevaluasi kinerja rekan kerja di tempat kerja.
                                </li>
                                <li>
                                    menceritakan peristiwa di masa lalu, termasuk kegiatan di akhir pekan dan cerita yang menarik.
                                </li>
                                <li>
                                    menghibur seseorang di rumah atau mengunjungi seorang teman atau kolega di rumah mereka.
                                </li>
                                <li>
                                    mendiskusikan rencana liburan dan menceritakan liburan kepada teman dan kolega sesudahnya.
                                </li>
                                <li>
                                    berbicara tentang alam dan bepergian untuk melihat hewan dan daerah alami di negara Anda
                                </li>
                                <li>
                                    berbicara tentang film yang Anda sukai dan memilih film yang akan ditonton bersama teman-teman.
                                </li>
                                <li>
                                    berdiskusi tentang busana dan jenis pakaian yang Anda sukai.
                                </li>
                                <li>
                                    terlibat dalam komunikasi sederhana di tempat kerja, termasuk menghadiri rapat mengenai topik yang telah dikenal.
                                </li>
                                <li>
                                    menggambarkan suatu kecelakaan atau cedera, meminta bantuan medis dari dokter, dan meminta resep obat.
                                </li>
                                <li>
                                    terlibat dalam sosialisasi bisnis sederhana, menyambut tamu, dan menghadiri acara sosial.
                                </li>
                                <li>
                                    memahami dan membuat proposal bisnis sederhana di bidang keahlian Anda.
                                </li>
                                <li>
                                    berbicara tentang dan menjelaskan aturan permainan.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Intermediate (level 3)</b> <br/>
                            Seorang pengguna <br/>
                            <b>Intermediate (level 3)</b> di bahasa Inggris akan dapat &rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    mendiskusikan harapan pribadi dan profesional serta impian untuk masa depan.
                                </li>
                                <li>
                                    melakukan wawancara kerja dan mengikuti wawancara kerja di bidang keahlian Anda.
                                </li>
                                <li>
                                    berbicara tentang kebiasaan menonton televisi dan acara kesukaan Anda.
                                </li>
                                <li>
                                    menjelaskan pendidikan Anda dan rencana Anda untuk pelatihan di masa mendatang.
                                </li>
                                <li>
                                    berbicara tentang musik kesukaan Anda dan tren musik serta merencanakan pergi ke pertunjukan musik di malam hari.
                                </li>
                                <li>
                                    berbicara tentang menjalani gaya hidup sehat serta memberi dan menerima saran tentang kebiasaan sehat.
                                </li>
                                <li>
                                    berbicara tentang hubungan dan kencan, termasuk berkenalan dengan orang-orang di media sosial.
                                </li>
                                <li>
                                    pergi ke rumah makan, memesan makanan, terlibat dalam percakapan saat makan malam, dan membayar makanan Anda.
                                </li>
                                <li>
                                    berpartisipasi dalam negosiasi di bidang keahlian Anda, jika Anda memiliki bantuan untuk memahami beberapa poin.
                                </li>
                                <li>
                                    mendiskusikan isu-isu keselamatan kerja, melaporkan kecelakaan, serta menjelaskan aturan dan regulasi.
                                </li>
                                <li>
                                    mendiskusikan perilaku sopan dan menanggapi perilaku kurang sopan dengan tepat.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Upper-Intermediate (level 4)</b> <br/>
                            Seorang pengguna <b>Upper-Intermediate (level 4)</b> di bahasa Inggris akan dapat &rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    berpartisipasi dalam rapat di bidang keahlian Anda, jika Anda memiliki bantuan untuk memahami beberapa poin.
                                </li>
                                <li>
                                    mendiskusikan isu-isu gender yang berkaitan dengan persepsi ketidaksopanan dan norma-norma budaya.
                                </li>
                                <li>
                                    berbicara tentang keuangan pribadi serta memberikan saran kepada teman dan kolega tentang keuangan mereka.
                                </li>
                                <li>
                                    berbicara tentang gaya hidup pribadi dan profesional, termasuk gambaran tentang kehidupan Anda di tempat kerja.
                                </li>
                                <li>
                                    menjelaskan pendidikan, pengalaman, kekuatan dan kelemahan Anda, serta mendiskusikan jalur karier Anda.
                                </li>
                                <li>
                                    berbicara tentang proses mental serta cara Anda menggunakannya untuk meningkatkan efektivitas bekerja Anda.
                                </li>
                                <li>
                                    berbicara tentang bacaan kesukaan Anda dan memberikan rekomendasi bacaan yang baik untuk dibaca.
                                </li>
                                <li>
                                    menggunakan bahasa yang sesuai dalam situasi sosial, termasuk memberikan pujian dan menyatakan simpati.
                                </li>
                                <li>
                                    berdiskusi tentang kualitas kepemimpinan dan berbicara tentang pemimpin yang Anda kagumi.
                                </li>
                                <li>
                                    menghadapi situasi canggung yang cukup rumit dalam konteks sosial dan bisnis.
                                </li>
                                <li>
                                    mendiskusikan situasi politik umum dan perilaku para politikus.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Advanced (level 5)</b> <br/>
                            Seorang pengguna <b>Advanced (level 5)</b> di bahasa Inggris akan dapat &rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    berdiskusi secara terperinci mengenai keberhasilan, termasuk membangun tim yang sukses dan termotivasi.
                                </li>
                                <li>
                                    berbicara secara terperinci mengenai lukisan favorit dan arsitektur bangunan yang Anda sukai.
                                </li>
                                <li>
                                    mendiskusikan masalah sosial, solusi atas masalah, dan peran yang dapat dilakukan oleh perusahaan.
                                </li>
                                <li>
                                    berpartisipasi dalam diskusi tentang pelestarian, keberlanjutan, dan perlindungan habitat.
                                </li>
                                <li>
                                    berbicara tentang peristiwa dan isu-isu dalam berita serta bagaimana pengaruhnya terhadap masyarakat dan perusahaan.
                                </li>
                                <li>
                                    berbicara tentang risiko dalam hidup, termasuk berganti pekerjaan dan melakukan olahraga berbahaya.
                                </li>
                                <li>
                                    membandingkan dan memperbedakan berbagai bentuk pendidikan dan sekolah.
                                </li>
                                <li>
                                    berdiskusi tentang berbagai jenis humor, termasuk bentuk-bentuk yang halus seperti sarkasme.
                                </li>
                                <li>
                                    memahami berbagai gaya komunikasi, termasuk langsung, tidak langsung, formal, dan informal.
                                </li>
                                <li>
                                    berdiskusi tentang masalah-masalah terkait kualitas hidup Anda, termasuk keseimbangan kehidupan pekerjaan dan pribadi, serta lingkungan rumah.
                                </li>
                                <li>
                                    memahami dan mendiskusikan masalah-masalah yang berkaitan dengan etika, seperti pembangkangan sipil.
                                </li>
                            </ul>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="col-12 px-0 mb-2" v-if="type == 'G' && indicator.G != 0">
            <div class="table-responsive">
                <table class="table text-black">
                    <tr>
                        <td colspan="2"><b>GLORY</b></td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            Program GLORY untuk yang berkebutuhan di ranah bisnis dan profesional. Program ini
                            sangat sesuai bagi pengguna untuk memiliki kompetensi dalam dunia kerja atau yang sedang
                            mempersiapkan diri memasuki dunia kerja dan profesional. Program GLORY memiliki
                            prasyarat bahwa pengguna sudah ada di level 3-5 atau di level Intermediate, UpperIntermediate, dan Advanced.

                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            Tingkatan (Level) 3-5
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Academic Writing (Session 1) (level 3)</b><br/> 
                            Seorang pengguna <b>Academic Writing (Session 1) (level 3)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td width="70%">
                            <ul>
                                <li>
                                    Tulis, revisi, dan edit paragraf untuk memperkenalkan diri
                                </li>
                                <li>
                                    Tulis, revisi, dan edit paragraf tentang rutinitas pagi Anda
                                </li>
                                <li>
                                    Tulis, revisi, dan edit paragraf tentang seseorang di foto
                                </li>
                                <li>
                                    Tulis, revisi, dan edit paragraf tentang hari-hari biasa teman sekelas.
                                </li>
                                <li>
                                    Tulis, revisi, dan edit paragraf tentang kampung halaman Anda
                                </li>
                                <li>
                                    Tulis, revisi, dan edit paragraf tentang seseorang di tempat kerja
                                </li>
                                <li>
                                    Tulis, revisi, dan edit paragraf tentang peristiwa penting dalam hidup Anda
                                </li>
                                <li>
                                    Tulis, revisi, dan edit paragraf tentang perjalanan
                                </li>
                                <li>
                                    Tulis, revisi, dan edit paragraf tentang masa depan Anda
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Academic Writing (Session 2) (level 3)</b><br/> 
                            Seorang pengguna Academic <b>Academic Writing (Session 2) (level 3)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Tulis, revisi, dan edit paragraf yang menggambarkan seseorang
                                </li>
                                <li>
                                    Gunakan urutan daftar untuk menulis paragraf yang berhubungan dengan karier
                                </li>
                                <li>
                                    Tulis, revisi, dan edit paragraf “petunjuk”.
                                </li>
                                <li>
                                    Menulis, merevisi, dan mengedit paragraf deskriptif.
                                </li>
                                <li>
                                    Tulis, revisi, dan edit paragraf dengan alasan dan contoh.
                                </li>
                                <li>
                                    Menulis, merevisi, dan mengedit paragraf opini
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Academic Writing (Session 3) (level 3)</b><br/> 
                            Seorang pengguna Academic <b>Academic Writing (Session 3) (level 3)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Tulis, revisi, dan edit paragraf akademik tentang kalimat yang telah membuat perbedaan.
                                </li>
                                <li>
                                    Tulis, revisi, dan edit paragraf naratif tentang pengalaman yang tak terlupakan.
                                </li>
                                <li>
                                    Tulis, revisi, dan edit paragraf akademis tentang hobi atau olahraga.
                                </li>
                                <li>
                                    Tulis, revisi, dan edit paragraf tentang kebiasaan berbelanja.
                                </li>
                                <li>
                                    Tulis, revisi, dan edit paragraf proses tentang peningkatan diri.
                                </li>
                                <li>
                                    Tulis, revisi, dan edit paragraf yang mendefinisikan kata, konsep, atau kebiasaan.
                                </li>
                                <li>
                                    Tulis, revisi, dan edit paragraf sebab atau akibat tentang masalah sosial.
                                </li>
                                <li>
                                    Tulis, revisi, dan edit paragraf perbandingan/kontras tentang pendidikan di dua negara yang berbeda.
                                </li>
                                <li>
                                    Menulis, merevisi, dan mengedit esai akademik tentang komunikasi nonverbal.
                                </li>
                                <li>
                                    Menulis, merevisi, dan mengedit esai opini tentang mendapatkan pendidikan yang baik.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Academic Writing (Session 4) (level 3)</b><br/> 
                            Seorang pengguna Academic <b>Academic Writing (Session 4) (level 3)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Menulis, merevisi, dan mengedit paragraf tentang komunikasi.
                                </li>
                                <li>
                                    Tulis, revisi, dan edit paragraf tentang kesehatan dan kedokteran.
                                </li>
                                <li>
                                    Menulis, merevisi, dan mengedit ringkasan artikel tentang bahasa.
                                </li>
                                <li>
                                    Menulis, merevisi, dan mengedit esai tentang pendidikan.
                                </li>
                                <li>
                                    Menulis, merevisi, dan mengedit esai proses tentang ilmu bumi.
                                </li>
                                <li>
                                    Menulis, merevisi, dan mengedit esai sebab/akibat tentang psikologi.
                                </li>
                                <li>
                                    Menulis, merevisi, dan mengedit esai perbandingan/kontras tentang budaya.
                                </li>
                                <li>
                                    Tulis, revisi, dan edit esai argumentatif tentang perjalanan luar angkasa.
                                </li>
                                <li>
                                    Buatlah sebuah paragraf yang menggunakan berbagai jenis kalimat.
                                </li>
                                <li>
                                    Tulis surat yang menyertakan kalimat dengan klausa kata benda.
                                </li>
                                <li>
                                    Tulislah sebuah paragraf yang memuat kalimat dengan klausa adverbia.
                                </li>
                                <li>
                                    Tulislah sebuah paragraf yang memuat kalimat dengan klausa kata sifat.
                                </li>
                                <li>
                                    Tulislah sebuah paragraf yang menyertakan partisip dan frasa partisipatif.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Business English (Session 1) (level 4)</b><br/> 
                            Seorang pengguna <b>Business English (Session 1) (level 4)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Memperkenalkan diri.
                                </li>
                                <li>
                                    Memperkenalkan orang lain.
                                </li>
                                <li>
                                    Informasi perusahaan.
                                </li>
                                <li>
                                    Arah.
                                </li>
                                <li>
                                    Penawaran, permintaan, dan izin.
                                </li>
                                <li>
                                    Pemesanan barang.
                                </li>
                                <li>
                                    Berbicara tentang proyek.
                                </li>
                                <li>
                                    Menjelaskan masalah teknis.
                                </li>
                                <li>
                                    Mendeskripsikan sebuah produk.
                                </li>
                                <li>
                                    <i>Catching up</i>.
                                </li>
                                <li>
                                    Opini.
                                </li>
                                <li>
                                    Wawancara.
                                </li>
                                <li>
                                    Mendiskusikan ide.
                                </li>
                                <li>
                                    Membuat pengaturan.
                                </li>
                                <li>
                                    Berbicara tentang tren.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Business English (Session 2) (level 4)</b><br/> 
                            Seorang pengguna <b>Business English (Session 2) (level 4)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Menjelaskan pekerjaan Anda.
                                </li>
                                <li>
                                    Memeriksa informasi.
                                </li>
                                <li>
                                    Menjadi sopan.
                                </li>
                                <li>
                                    Menggambarkan produk.
                                </li>
                                <li>
                                    Menceritakan sebuah cerita.
                                </li>
                                <li>
                                    Membuat prediksi.
                                </li>
                                <li>
                                    Membuat janji.
                                </li>
                                <li>
                                    Menjelaskan apa yang harus dilakukan.
                                </li>
                                <li>
                                    Membuat saran.
                                </li>
                                <li>
                                    Mendeskripsikan grafik.
                                </li>
                                <li>
                                    Mengekspresikan argumen.
                                </li>
                                <li>
                                    Mengatasi masalah.
                                </li>
                                <li>
                                    Mengatur waktu.
                                </li>
                                <li>
                                    Menemukan solusi kreatif.
                                </li>
                                <li>
                                    Memberikan alasan.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Business English (Session 3) (level 4)</b><br/> 
                            Seorang pengguna <b>Business English (Session 3) (level 4)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Berbicara tentang pekerjaan Anda.
                                </li>
                                <li>
                                    Menyelesaikan semuanya.
                                </li>
                                <li>
                                    Presentasi singkat.
                                </li>
                                <li>
                                    Mengevaluasi kinerja.
                                </li>
                                <li>
                                    Menunjukkan sebab dan akibat.
                                </li>
                                <li>
                                    Mempertimbangkan alternatif.
                                </li>
                                <li>
                                    Membuat dan menanggapi saran.
                                </li>
                                <li>
                                    Mengacu pada visual.
                                </li>
                                <li>
                                    Obrolan kecil.
                                </li>
                                <li>
                                    Memberikan alasan.
                                </li>
                                <li>
                                    Membuat dan menanggapi penawaran.
                                </li>
                                <li>
                                    Membuat kasus.
                                </li>
                                <li>
                                    Meringkas.
                                </li>
                                <li>
                                    Berurusan dengan pertanyaan.
                                </li>
                                <li>
                                    Meninjau pencapaian.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Business English (Session 4) (level 4)</b><br/> 
                            Seorang pengguna <b>Business English (Session 4) (level 4)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Membangun hubungan.
                                </li>
                                <li>
                                    Menetapkan tujuan.
                                </li>
                                <li>
                                    Membangun tim.
                                </li>
                                <li>
                                    Teknik bertanya.
                                </li>
                                <li>
                                    Arahan.
                                </li>
                                <li>
                                    Bercerita.
                                </li>
                                <li>
                                    Negosiasi.
                                </li>
                                <li>
                                    Berurusan dengan orang-orang di tempat kerja.
                                </li>
                                <li>
                                    Memprioritaskan.
                                </li>
                                <li>
                                    Penyelesaian masalah.
                                </li>
                                <li>
                                    Membuat presentasi.
                                </li>
                                <li>
                                    Menangani konflik.
                                </li>
                                <li>
                                    Membujuk.
                                </li>
                                <li>
                                    Memotivasi.
                                </li>
                                <li>
                                    Pelaporan.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Business English (Session 5) (level 4)</b><br/> 
                            Seorang pengguna <b>Business English (Session 5) (level 4)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Mengelola penilaian.
                                </li>
                                <li>
                                    Mengelola resistensi terhadap perubahan.
                                </li>
                                <li>
                                    Mengambil tanggung jawab.
                                </li>
                                <li>
                                    Menyajikan argumen.
                                </li>
                                <li>
                                    Anggukan.
                                </li>
                                <li>
                                    Berdebat.
                                </li>
                                <li>
                                    Memengaruhi.
                                </li>
                                <li>
                                    Pengambilan keputusan.
                                </li>
                                <li>
                                    Negosiasi.
                                </li>
                                <li>
                                    Membuat keputusan etis.
                                </li>
                                <li>
                                    Melatih.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Business English (Session 1) (level 5)</b><br/> 
                            Seorang pengguna <b>Business English (Session 1) (level 5)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Berbicara di depan umum.
                                </li>
                                <li>
                                    Etika dan berbicara di depan umum.
                                </li>
                                <li>
                                    Mendengarkan.
                                </li>
                                <li>
                                    Memberikan pidato pertama Anda.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Business English (Session 2) (level 5)</b><br/> 
                            Seorang pengguna <b>Business English (Session 2) (level 5)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Memilih topik dan tujuan.
                                </li>
                                <li>
                                    Menganalisis penonton.
                                </li>
                                <li>
                                    Mengumpulkan bahan.
                                </li>
                                <li>
                                    Mendukung ide-ide Anda.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Business English (Session 3) (level 5)</b><br/> 
                            Seorang pengguna <b>Business English (Session 3) (level 5)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Mengorganisir badan pidato.
                                </li>
                                <li>
                                    Memulai dan mengakhiri pidato.
                                </li>
                                <li>
                                    Menguraikan pidato.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Business English (Session 4) (level 5)</b><br/> 
                            Seorang pengguna <b>Business English (Session 4) (level 5)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Menggunakan bahasa.
                                </li>
                                <li>
                                    Pengiriman.
                                </li>
                                <li>
                                    Menggunakan alat bantu visual.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Business English (Session 5) (level 5)</b><br/> 
                            Seorang pengguna <b>Business English (Session 5) (level 5)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Berbicara untuk menginformasikan.
                                </li>
                                <li>
                                    Berbicara untuk membujuk.
                                </li>
                                <li>
                                    Metode persuasi.
                                </li>
                                <li>
                                    Berbicara pada acara-acara khusus.
                                </li>
                                <li>
                                    Menyajikan pidato Anda secara daring.
                                </li>
                                <li>
                                    Berbicara dalam kelompok kecil.
                                </li>
                            </ul>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="col-12 px-0 mb-2" v-if="type == 'T' && indicator.T != 0">
            <div class="table-responsive">
                <table class="table text-black">
                    <tr>
                        <td colspan="2"><b>TRIUMPH</b></td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            Program TRIUMPH untuk yang berkebutuhan mempersiapkan tes bahasa Inggris
                            internasional. Program ini sangat sesuai bagi pengguna untuk memiliki kompetensi dalam
                            tes berbahasa Inggris berbagai macam kebutuhan. Program TRIUMPH memiliki prasyarat
                            bahwa pengguna sudah ada di level 3-5 atau di level Intermediate, Upper-Intermediate, dan
                            Advanced
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            Tingkatan (Level) 3-5
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>TOEFL ITP (Session 1) (level 3)</b><br/> 
                            Seorang pengguna <b>TOEFL ITP (Session 1) (level 3)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Menyimak kata kunci.
                                </li>
                                <li>
                                    Menyimak idiom.
                                </li>
                                <li>
                                    Menyimak kondisi, penyebab, dan modal.
                                </li>
                                <li>
                                    Menyimak ekspresi negatif.
                                </li>
                                <li>
                                    Menyimak waktu, kuantitas, dan perbandingan.
                                </li>
                                <li>
                                    Pengertian intonasi.
                                </li>
                                <li>
                                    Menyimak implikasi dari pembicara.
                                </li>
                                <li>
                                    Membuat kesimpulan tentang orang.
                                </li>
                                <li>
                                    Membuat kesimpulan tentang konteks.
                                </li>
                                <li>
                                    Mengidentifikasi topik dan gagasan utama.
                                </li>
                                <li>
                                    Memahami detail.
                                </li>
                                <li>
                                    Mencocokkan kata dan kategori.
                                </li>
                                <li>
                                    Mengurutkan peristiwa dalam suatu proses.
                                </li>
                                <li>
                                    Menentukan alasan.
                                </li>
                                <li>
                                    Membuat kesimpulan dan prediksi.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>TOEFL ITP (Session 2) (level 3)</b><br/> 
                            Seorang pengguna <b>TOEFL ITP (Session 2) (level 3)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Memeriksa subjek dan objek.
                                </li>
                                <li>
                                    Mengenali apositif.
                                </li>
                                <li>
                                    Memeriksa jamak.
                                </li>
                                <li>
                                    Memeriksa artikel.
                                </li>
                                <li>
                                    Memeriksa kata kerja dan pembantu.
                                </li>
                                <li>
                                    Mengenali klausa utama dan anak kalimat.
                                </li>
                                <li>
                                    Mengenali klausa kata benda.
                                </li>
                                <li>
                                    Mengenali klausa dan frasa kata sifat.
                                </li>
                                <li>
                                    Mengenali klausa dan frasa adverbia.
                                </li>
                                <li>
                                    Mengenali persyaratan.
                                </li>
                                <li>
                                    Mengenali subjek dan kata kerja terbalik.
                                </li>
                                <li>
                                    Memeriksa kesepakatan subjek-kata kerja.
                                </li>
                                <li>
                                    Memeriksa persetujuan kata ganti.
                                </li>
                                <li>
                                    Memeriksa urutan kata sifat.
                                </li>
                                <li>
                                    Memeriksa kata sifat partisipatif.
                                </li>
                                <li>
                                    Memeriksa pengubah negatif.
                                </li>
                                <li>
                                    Memeriksa persamaan, komparatif, dan superlatif.
                                </li>
                                <li>
                                    Memeriksa konjungsi.
                                </li>
                                <li>
                                    Memeriksa preposisi.
                                </li>
                                <li>
                                    Memeriksa bentuk kata.
                                </li>
                                <li>
                                    Memeriksa pilihan kata.
                                </li>
                                <li>
                                    Mengenal Redundansi.
                                </li>
                                <li>
                                    Mengenali struktur paralel.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>TOEFL ITP (Session 3) (level 3)</b><br/> 
                            Seorang pengguna <b>TOEFL ITP (Session 3) (level 3)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Mengidentifikasi topik dan gagasan utama.
                                </li>
                                <li>
                                    Memahami detail dan ide pendukung.
                                </li>
                                <li>
                                    Menemukan referensi.
                                </li>
                                <li>
                                    Memahami kosa kata dalam konteks.
                                </li>
                                <li>
                                    Membuat kesimpulan.
                                </li>
                                <li>
                                    Mengidentifikasi organisasi dan tujuan.
                                </li>
                                <li>
                                    Mengenali koherensi.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>TOEIC (Session 1) (level 3)</b><br/> 
                            Seorang pengguna <b>TOEIC (Session 1) (level 3)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Foto orang.
                                </li>
                                <li>
                                    Foto benda.
                                </li>
                                <li>
                                    Mengidentifikasi waktu (pertanyaan-respons).
                                </li>
                                <li>
                                    Mengidentifikasi orang (pertanyaan-respons).
                                </li>
                                <li>
                                    Mengidentifikasi pendapat (tanya jawab).
                                </li>
                                <li>
                                    Mengidentifikasi pilihan.
                                </li>
                                <li>
                                    Mengidentifikasi saran.
                                </li>
                                <li>
                                    Mengidentifikasi alasan (pertanyaan-respons).
                                </li>
                                <li>
                                    Mengidentifikasi lokasi (pertanyaan-respons).
                                </li>
                                <li>
                                    Mengidentifikasi waktu (percakapan).
                                </li>
                                <li>
                                    Mengidentifikasi orang (percakapan).
                                </li>
                                <li>
                                    Mengidentifikasi niat.
                                </li>
                                <li>
                                    Mengidentifikasi topik.
                                </li>
                                <li>
                                    Mengidentifikasi alasan.
                                </li>
                                <li>
                                    Mengidentifikasi lokasi.
                                </li>
                                <li>
                                    Mengidentifikasi pendapat (percakapan).
                                </li>
                                <li>
                                    Mengidentifikasi stres dan nada.
                                </li>
                                <li>
                                    Mengidentifikasi urutan.
                                </li>
                                <li>
                                    Mengidentifikasi audiens.
                                </li>
                                <li>
                                    Mengidentifikasi situasi.
                                </li>
                                <li>
                                    Mengidentifikasi topik (pembicaraan).
                                </li>
                                <li>
                                    Mengidentifikasi permintaan.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>TOEIC (Session 2) (level 3)</b><br/> 
                            Seorang pengguna <b>TOEIC (Session 2) (level 3)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Keluarga kata.
                                </li>
                                <li>
                                    Kata-kata serupa.
                                </li>
                                <li>
                                    Preposisi.
                                </li>
                                <li>
                                    Konjungsi.
                                </li>
                                <li>
                                    Keterangan frekuensi.
                                </li>
                                <li>
                                    Kata kerja penyebab.
                                </li>
                                <li>
                                    Kalimat bersyarat.
                                </li>
                                <li>
                                    Kata kerja tegang.
                                </li>
                                <li>
                                    Kata kerja dua kata.
                                </li>
                                <li>
                                    Kata-kata dalam konteks bersama.
                                </li>
                                <li>
                                    Kata ganti.
                                </li>
                                <li>
                                    kesepakatan subjek-kata kerja.
                                </li>
                                <li>
                                    Modal auxiliaries.
                                </li>
                                <li>
                                    Perbandingan kata sifat.
                                </li>
                                <li>
                                    Gerund atau Infinitif.
                                </li>
                                <li>
                                    Strategi membaca.
                                </li>
                                <li>
                                    Pemahaman membaca (iklan; korespondensi bisnis; formulir, bagan, dan grafik; artikel dan laporan; pengumuman dan paragraf).
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>TOEIC iBT (Session 1) (level 4)</b><br/> 
                            Seorang pengguna <b>TOEIC iBT(Session 1) (level 4)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Nyatakan gagasan utama.
                                </li>
                                <li>
                                    Menganalisis struktur.
                                </li>
                                <li>
                                    Menganalisis paragraf tubuh.
                                </li>
                                <li>
                                    Menganalisis kesimpulan.
                                </li>
                                <li>
                                    Menemukan arah.
                                </li>
                                <li>
                                    Temukan tujuannya.
                                </li>
                                <li>
                                    Trim the fat.
                                </li>
                                <li>
                                    Kosakata yang superlatif.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>TOEIC iBT (Session 2) (level 4)</b><br/> 
                            Seorang pengguna <b>TOEIC iBT(Session 2) (level 4)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Temukan ide atau tujuan utama.
                                </li>
                                <li>
                                    Fokus pada struktur.
                                </li>
                                <li>
                                    Dengarkan nada dan sikap.
                                </li>
                                <li>
                                    Perhatikan transisi.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>TOEIC iBT (Session 3) (level 4)</b><br/> 
                            Seorang pengguna <b>TOEIC iBT(Session 3) (level 4)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Berlatih berbicara pengantar pidato.
                                </li>
                                <li>
                                    Berlatih berbicara dengan contoh.
                                </li>
                                <li>
                                    Berlatih berbicara pengantar dan badan paragraf.
                                </li>
                                <li>
                                    Kembangkan kesimpulan untuk berbicara.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>TOEIC iBT (Session 4) (level 4)</b><br/> 
                            Seorang pengguna <b>TOEIC iBT(Session 4) (level 4)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Tulis paragraf pendahuluan.
                                </li>
                                <li>
                                    Tulis badan paragraf yang menggunakan contoh.
                                </li>
                                <li>
                                    Menulis badan paragraf.
                                </li>
                                <li>
                                    Tulis kesimpulan.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>IELTS (Session 1) (level 5)</b><br/> 
                            Seorang pengguna <b>IELTS(Session 1) (level 5)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Jenis pertanyaan dalam tes IELTS Listening.
                                </li>
                                <li>
                                    Item leksikal yang diklasifikasi (1).
                                </li>
                                <li>
                                    Item leksikal yang diklasifikasi (2).
                                </li>
                                <li>
                                    Soal pilihan ganda.
                                </li>
                                <li>
                                    Pengisian celah: penyelesaian ringkasan.
                                </li>
                                <li>
                                    Pengisian celah: pengisian formulir/catatan/tabel.
                                </li>
                                <li>
                                    Pengisian celah: penyelesaian kalimat.
                                </li>
                                <li>
                                    Mencocokkan kata/frasa/kalimat.
                                </li>
                                <li>
                                    Mencocokkan dengan gambar.
                                </li>
                                <li>
                                    Jawaban pendek.
                                </li>
                                <li>
                                    Pertanyaan benar/salah.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>IELTS (Session 2) (level 5)</b><br/> 
                            Seorang pengguna <b>IELTS(Session 2) (level 5)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Pertanyaan ikhtisar.
                                </li>
                                <li>
                                    Pertanyaan detail: pertanyaan jawaban singkat/mengisi celah/pilihan ganda/klasifikasi.
                                </li>
                                <li>
                                    Detail pertanyaan: benar/salah/tidak diberikan/kalimat-penyelesaian/pertanyaan yang cocok.
                                </li>
                                <li>
                                    Pertanyaan sudut pandang.
                                </li>
                                <li>
                                    Pertanyaan ringkasan.
                                </li>
                                <li>
                                    Pertanyaan grafis.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>IELTS (Session 3) (level 5)</b><br/> 
                            Seorang pengguna <b>IELTS(Session 3) (level 5)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Gambaran Umum Tes Berbicara Bagian 1.
                                </li>
                                <li>
                                    Bagian 1: topik umum (1).
                                </li>
                                <li>
                                    Bagian 1: topik umum (2).
                                </li>
                                <li>
                                    Bagian 1: topik umum (3).
                                </li>
                                <li>
                                    Bagian 1: topik umum (4).
                                </li>
                                <li>
                                    Gambaran Umum Tes Berbicara Bagian 2.
                                </li>
                                <li>
                                    Bagian 2: topik umum (1).
                                </li>
                                <li>
                                    Bagian 2: topik umum (2).
                                </li>
                                <li>
                                    Bagian 2: topik umum (3).
                                </li>
                                <li>
                                    Tinjauan Berbicara Bagian 3.
                                </li>
                                <li>
                                    Bagian 3: topik umum (1).
                                </li>
                                <li>
                                    Bagian 3: topik umum (2).
                                </li>
                                <li>
                                    Bagian 3: topik umum (3).
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>IELTS (Session 4) (level 5)</b><br/> 
                            Seorang pengguna <b>IELTS(Session 4) (level 5)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Sekilas Tes Menulis.
                                </li>
                                <li>
                                    Penulisan laporan (1): tugas 1.
                                </li>
                                <li>
                                    Penulisan laporan (2): tugas 1.
                                </li>
                                <li>
                                    Proses atau penulisan diagram: tugas 1.
                                </li>
                                <li>
                                    Penulisan esai: tugas 2.
                                </li>
                                <li>
                                    Opini dan esai berargumen seimbang.
                                </li>
                                <li>
                                    Esai diskusi.
                                </li>
                                <li>
                                    Esai enumerasi.
                                </li>
                                <li>
                                    Kemahiran menulis esai argumen.
                                </li>
                                <li>
                                    Contoh esai.
                                </li>
                            </ul>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="col-12 px-0 mb-2" v-if="type == 'E' && indicator.E != 0">
            <div class="table-responsive">
                <table class="table text-black">
                    <tr>
                        <td colspan="2"><b>EXCLUSIVE</b></td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            Program EXCLUSIVE untuk yang berkebutuhan di ranah akademis dan tujuan khusus
                            (profesi). Program ini sangat sesuai bagi pengguna untuk memiliki kompetensi dalam dunia
                            akademis dan/atau profesi secara spesifik. Program EXCLUSIVE memiliki prasyarat bahwa
                            pengguna sudah ada di level 2-5 atau di level Elementary, Intermediate, Upper-Intermediate,
                            dan Advanced.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            Tingkatan (Level) 2-5
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Listening & Speaking 1 (Academic) (level 2)</b><br/> 
                            Seorang pengguna <b>Listening & Speaking 1 (Academic) (level 2)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Pengantar note-taking; berpartisipasi dalam diskusi kelompok.
                                </li>
                                <li>
                                    Mengategorikan nama dan tanggal; meminta dan memberi contoh.
                                </li>
                                <li>
                                    Memahami bilangan urut dan persentase; menggunakan statistik dalam pidato singkat.
                                </li>
                                <li>
                                    Mengorganisir fakta dan opini; mengungkapkan pendapat.
                                </li>
                                <li>
                                    Mendengarkan gagasan utama; menggunakan contoh untuk mendukung gagasan utama.
                                </li>
                                <li>
                                    Membuat garis waktu; urutan kata dan frase.
                                </li>
                                <li>
                                    Menganotasi catatan kuliah; meminta klarifikasi.
                                </li>
                                <li>
                                    Mengenali bahasa yang menandakan suatu definisi; mengatur presentasi singkat.
                                </li>
                                <li>
                                    Mendengarkan informasi spesifik; memberikan saran dan membuat rekomendasi.
                                </li>
                                <li>
                                    Mendengarkan alasan dan penjelasan; parafrase para ahli untuk mendukung pendapat.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Reading 1 (Academic) (level 2)</b><br/> 
                            Seorang pengguna <b>Reading 1 (Academic) (level 2)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Pratinjau.
                                </li>
                                <li>
                                    Menemukan ide pokok.
                                </li>
                                <li>
                                    Memindai.
                                </li>
                                <li>
                                    Mengidentifikasi contoh.
                                </li>
                                <li>
                                    Mengidentifikasi definisi.
                                </li>
                                <li>
                                    Mengidentifikasi waktu dan urutan kata.
                                </li>
                                <li>
                                    Membaca tabel numerik.
                                </li>
                                <li>
                                    Membuat kesimpulan.
                                </li>
                                <li>
                                    Membaca tabel statistik.
                                </li>
                                <li>
                                    Membedakan fakta dari opini.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Writing 1 (Academic) (level 2)</b><br/> 
                            Seorang pengguna <b>Writing 1 (Academic) (level 2)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Bahasa deskriptif; struktur paragraf dasar.
                                </li>
                                <li>
                                    Organisasi pemecahan masalah; meminta dan memberi nasehat.
                                </li>
                                <li>
                                    Gagasan dan detail utama; meringkas.
                                </li>
                                <li>
                                    Mendukung pendapat; kalimat topik.
                                </li>
                                <li>
                                    Menganalisis narasi; menggunakan kait.
                                </li>
                                <li>
                                    Menganalisis pertanyaan dan tanggapan tes; memilih informasi yang relevan.
                                </li>
                                <li>
                                    Perbandingan; ragam kalimat.
                                </li>
                                <li>
                                    Menganalisis audiens; mengatur informasi.
                                </li>
                                <li>
                                    Menggambarkan suatu proses; bahasa akademik.
                                </li>
                                <li>
                                    Sebab dan akibat; membuat paragraf.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Listening & Speaking 2 (Academic) (level 3)</b><br/> 
                            Seorang pengguna <b> Listening & Speaking 2 (Academic) (level 3)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Hubungan sebab dan akibat; memfasilitasi diskusi kelompok.
                                </li>
                                <li>
                                    Singkatan dan simbol; mendukung pendapat.
                                </li>
                                <li>
                                    Frase sinyal; berbagi pendapat dengan sopan.
                                </li>
                                <li>
                                    Garis besar catatan; memberikan ringkasan lisan singkat.
                                </li>
                                <li>
                                    Mendengarkan proposal; memberi dan menanggapi proposal.
                                </li>
                                <li>
                                    Metode Cornell untuk mencatat; menyiapkan presentasi yang terorganisir dengan baik.
                                </li>
                                <li>
                                    Mendengarkan implikasi; permintaan dan interupsi yang sopan.
                                </li>
                                <li>
                                    Menggunakan peta pikiran; memeriksa pemahaman.
                                </li>
                                <li>
                                    Fakta dan opini; persuasif dalam diskusi akademik.
                                </li>
                                <li>
                                    Menggunakan konteks untuk mengikuti kuliah; komunikasi nonverbal.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Reading 2 (Academic) (level 2)</b><br/> 
                            Seorang pengguna <b> Reading 2 (Academic) (level 2)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Pratinjau dan prediksi.
                                </li>
                                <li>
                                    Menemukan gagasan utama; menemukan rincian.
                                </li>
                                <li>
                                    Memindai.
                                </li>
                                <li>
                                    Menguraikan.
                                </li>
                                <li>
                                    Membaca bagan dan grafik.
                                </li>
                                <li>
                                    Meringkas.
                                </li>
                                <li>
                                    Membuat kesimpulan.
                                </li>
                                <li>
                                    Menyoroti dan memberi anotasi.
                                </li>
                                <li>
                                    Memahami urutan.
                                </li>
                                <li>
                                    Merekam proses dengan diagram alir.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Writing 2 (Academic) (level 2)</b><br/> 
                            Seorang pengguna <b> Writing 2 (Academic) (level 2)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Menganalisis narasi; struktur paragraf.
                                </li>
                                <li>
                                    Audiens dan tujuan; mengatur informasi.
                                </li>
                                <li>
                                    Mendukung pendapat dengan fakta; penulisan judul yang baik.
                                </li>
                                <li>
                                    Mendukung sudut pandang; meringkas sebuah cerita.
                                </li>
                                <li>
                                    Memilih informasi yang relevan; menggunakan register yang benar.
                                </li>
                                <li>
                                    Menganalisis ide; mengatur bahasa.
                                </li>
                                <li>
                                    Menganalisis penjelasan kausal; menanggapi pertanyaan tes.
                                </li>
                                <li>
                                    Anekdot; menulis tesis.
                                </li>
                                <li>
                                    Menganalisis kesimpulan; menulis hook yang bagus.
                                </li>
                                <li>
                                    Definisi; menulis untuk khalayak yang berbeda.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Listening & Speaking 3 (Academic) (level 4)</b><br/> 
                            Seorang pengguna <b> Listening & Speaking 3 (Academic) (level 4)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Menggunakan singkatan dalam catatan; frase isyarat dalam pidato.
                                </li>
                                <li>
                                    Gunakan T-chart untuk membuat catatan; mendukung pendapat Anda dalam diskusi.
                                </li>
                                <li>
                                    Perbandingan dan kontras; meringkas informasi.
                                </li>
                                <li>
                                    Bahasa kiasan; menggunakan idiom untuk melibatkan audiens Anda.
                                </li>
                                <li>
                                    Mendengarkan detail; membuat presentasi singkat.
                                </li>
                                <li>
                                    Menganotasi catatan kuliah; menandakan akhir pidato.
                                </li>
                                <li>
                                    Metode Cornell untuk mencatat; meminta dan memberikan penjelasan.
                                </li>
                                <li>
                                    Mendengarkan fakta dan opini; mengutip informasi.
                                </li>
                                <li>
                                    Mengenali pidato persuasif; memfasilitasi diskusi kelompok.
                                </li>
                                <li>
                                    Menyintesis informasi; berpartisipasi dalam sebuah debat.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Reading 3 (Academic) (level 4)</b><br/> 
                            Seorang pengguna <b> Reading 3 (Academic) (level 4)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Pratinjau dan prediksi.
                                </li>
                                <li>
                                    Mengidentifikasi gagasan utama vs. detail pendukung.
                                </li>
                                <li>
                                    Membaca sekilas dan membuat prediksi.
                                </li>
                                <li>
                                    Menafsirkan bagan, grafik, dan tabel.
                                </li>
                                <li>
                                    Meringkas teks menggunakan elemen non teks.
                                </li>
                                <li>
                                    Membuat kesimpulan.
                                </li>
                                <li>
                                    Menganotasi dan menyorot teks.
                                </li>
                                <li>
                                    Mengenali perbandingan dan kontras.
                                </li>
                                <li>
                                    Memahami sinyal waktu.
                                </li>
                                <li>
                                    Mengidentifikasi dan memahami metafora.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Writing 3 (Academic) (level 2)</b><br/> 
                            Seorang pengguna <b> Writing 3 (Academic) (level 2)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Bahasa deskriptif; organisasi spasial.
                                </li>
                                <li>
                                    Audiens dan tujuan; menulis tentang data.
                                </li>
                                <li>
                                    fakta dan opini; masalah dan solusi.
                                </li>
                                <li>
                                    Pengorganisasian informasi; definisi yang diperluas.
                                </li>
                                <li>
                                    Bahasa evaluatif; mendukung suatu sudut pandang.
                                </li>
                                <li>
                                    Daftar resmi; mengusulkan solusi.
                                </li>
                                <li>
                                    Sebab dan akibat; menulis hipotesis.
                                </li>
                                <li>
                                    Contoh; membandingkan dan mengontraskan.
                                </li>
                                <li>
                                    Narasi pribadi; ragam kalimat.
                                </li>
                                <li>
                                    Argumen tandingan; mendukung pernyataan tesis.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Listening & Speaking 4 (Academic) (level 5)</b><br/> 
                            Seorang pengguna <b> Listening & Speaking 4 (Academic) (level 5)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Analogi dan metafora; memperkenalkan presentasi.
                                </li>
                                <li>
                                    Menguraikan catatan kuliah; menyatakan, mengulang, dan mengilustrasikan.
                                </li>
                                <li>
                                    Definisi rekaman; memfasilitasi diskusi kelompok.
                                </li>
                                <li>
                                    hubungan sebab-akibat; mengutip sumber.
                                </li>
                                <li>
                                    Mendengarkan frasa sinyal; mengungkapkan dan menanggapi suatu pendapat.
                                </li>
                                <li>
                                    Kesimpulan; memeriksa pemahaman.
                                </li>
                                <li>
                                    Bahasa telegraf; meringkas penelitian akademik.
                                </li>
                                <li>
                                    Metode Pencatatan Cornell; mendukung suatu pendapat.
                                </li>
                                <li>
                                    Metode Pencatatan Cornell; mendukung suatu pendapat.
                                </li>
                                <li>
                                    Fakta dan opini; permintaan dan interupsi yang sopan.
                                </li>
                                <li>
                                    Menyintesis informasi; penalaran deduktif dan induktif.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Writing 4 (Academic) (level 2)</b><br/> 
                            Seorang pengguna <b> Writing 4 (Academic) (level 2)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Skimming.
                                </li>
                                <li>
                                    Ringkasan; parafrase.
                                </li>
                                <li>
                                    Menulis proposal; menulis sinopsis.
                                </li>
                                <li>
                                    Gagasan pendukung; mengembangkan paragraf.
                                </li>
                                <li>
                                    Informasi lama-baru; meringkas data.
                                </li>
                                <li>
                                    Sebab dan akibat; mendukung dengan akal.
                                </li>
                                <li>
                                    Menggunakan sumber; menggunakan kutipan.
                                </li>
                                <li>
                                    Masalah dan solusi; evaluasi.
                                </li>
                                <li>
                                    Membahas dua sisi dari sebuah isu; tesis yang tertunda.
                                </li>
                                <li>
                                    Membandingkan dan mengontraskan sumber; pendahuluan dan kesimpulan.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Listening & Speaking 1-2 (Specific Purposes) (level 2-3)</b><br/> 
                            Seorang pengguna <b> Listening & Speaking 1-2 (Specific Purposes) (level 2-3)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Disesuaikan (tailored).
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Reading 1-2 (Specific Purposes) (level 2-3)</b><br/> 
                            Seorang pengguna <b> Reading 1-2 (Specific Purposes) (level 2-3)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Disesuaikan (tailored).
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Writing 1-2 (Specific Purposes) (level 2-3)</b><br/> 
                            Seorang pengguna <b> Writing 1-2 (Specific Purposes) (level 2-3)</b> di bahasa Inggris akan dapat&rarr;
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Disesuaikan (tailored).
                                </li>
                            </ul>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['type', 'indicator']
}
</script>
<style scoped>
ul {
    padding-left: 20px;
}
ul li {
    list-style-type: disc !important;
}
</style>